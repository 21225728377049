.features {
  background: white;
  text-align: left;
  margin: 0 auto;
  padding: 0 20px;
}

.features-wrapper {
  align-items: center;
  padding-top: 60px;
  padding-bottom: 60px;

  div.col-lg-6:first-child {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.sample-report {
  margin-top: 48px !important;
  padding: 48px 96px 64px 96px;
  border: 1px solid var(--brand-blue);
  border-radius: var(--corner-large);

  .take-a-look-btn {
    background: #075ad81f;
    color: var(--brand-blue);
    border: none;
    padding: 10px 16px;
    border-radius: var(--corner-small);
    position: absolute;
    right: 0;
    line-height: 18px;

    &:hover {
      background: #075ad835;
      box-shadow: 0 2px 4px rgba(7, 90, 216, 0.1);
    }

    a {
      text-decoration: none;
    }
  }
}

.sample-report-header {
  display: flex;
  align-items: center;
  margin-bottom: 48px;
  position: relative;
}

.sample-report-title {
  color: black;
  text-align: center;
  font-weight: 700;
  margin: 0;
  flex: 1;
}

.features-title {
  font-size: var(--header-font-size);
  font-weight: bold;
  color: var(--brand-blue);
  margin-bottom: 48px;
}

.features-content {
  max-width: 540px;
}

.features-description {
  font-size: var(--body-font-size);
  line-height: 1.5;
  margin-bottom: 16px;
  color: var(--brand-black);
}

.highlight {
  font-weight: bold;
}

.features-quotes-wrapper {
  display: flex;
}

#ai-analysis {
  .features-quotes-wrapper {
    justify-content: center;
  }
}

.features-quotes {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.quote-box {
  background: #f3f4f6; /* Light gray background */
  border-radius: 12px;
  padding: 24px;
  max-width: 400px;
}

.quote-text {
  font-size: 18px;
  color: var(--brand-black);
  margin: 0;
  font-family: 'Kotta One', serif;
}

.quote-text span {
  display: block;
}

.features-video {
  height: 524px;
  width: 100%;
  max-width: 500px;
  object-fit: contain;
}

.image-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  border: 1px solid #a7b4c9;
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 12px rgba(167, 180, 201, 0.2);
    transform: translateY(-2px);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 1199px) {
  .container {
    max-width: 100%;
    padding: 0 20px;
  }

  .features-content {
    max-width: 100%;
  }

  .features-quotes-wrapper {
    margin-top: 36px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    justify-content: flex-start !important;
  }

  .sample-report {
    padding: 24px 32px 32px 32px;
  }

  .sample-report-header {
    margin-bottom: 24px;
  }

  .video-wrapper {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .quote-text span {
    display: inline;
  }

  .features-wrapper {
    padding: 12px;
  }

  .features-title {
    font-size: var(--header-font-size-mobile);
    margin-bottom: 24px;
  }

  .sample-report {
    padding: 12px 16px 16px 16px;
    margin-top: 24px !important;
  }

  .sample-report-header {
    margin-bottom: 12px !important;
  }

  .sample-report-title {
    text-align: left !important;
  }

  .take-a-look-btn {
    padding: 8px 12px;
    line-height: 16px;
  }

  .features-description {
    font-size: var(--note-font-size);
  }
}

@media (max-width: 492px) {
  .features-container {
    padding-left: 0;
    padding-right: 0;
  }
}
