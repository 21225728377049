/* Basic styling for rc-steps */
.rc-steps {
  width: 100%;
}

.rc-steps-item {
  margin-bottom: 20px;
}

.rc-steps-item-title {
  font-size: 18px;
  font-weight: 500;
}

.rc-steps-item-description {
  font-size: 14px;
}

.rc-steps-item-finish {
  .rc-steps-item-icon {
    border-color: var(--brand-blue) !important;

    > .rc-steps-icon::before {
      content: '✓' !important;
      font-size: 14px;
      font-weight: bold;
      color: var(--brand-blue) !important;
    }
  }

  .rc-steps-item-tail::after {
    background-color: var(--brand-blue) !important;
  }
}

.rc-steps-item-process .rc-steps-item-icon {
  border-color: var(--brand-blue) !important;
  background-color: var(--brand-blue) !important;
}

.try-it-out-container {
  display: flex;
  gap: 0px;
  padding: 24px 0px;
}

.steps-section {
  flex: 0 0 300px;
  height: 524px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.note-text {
  margin-top: 16px;
  color: #666;
  font-size: 14px;
}

.interaction-section {
  height: 524px;
  width: 100%;
}

.upload-area {
  border: 2px dashed #e0e0e0;
  border-radius: 12px;
  padding: 48px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.upload-icon {
  margin-bottom: 16px;
}

.upload-area h3 {
  font-size: 20px;
  margin-bottom: 8px;
}

.upload-area p {
  color: #666;
  margin-bottom: 16px;
}

.upload-button {
  padding: 12px 24px;
}

.preview-area {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid #e0e0e0;
}

.preview-grid {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 24px;
  padding: 24px;
  overflow-y: auto;
}

.analysis-step .preview-grid {
  display: flex;
  flex-wrap: nowrap;
  gap: 24px;
  padding: 24px;
  overflow-x: auto;
}

.preview-item {
  aspect-ratio: 1;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.preview-actions {
  display: flex;
  justify-content: space-between;
  padding: 24px;

  .btn-outline-primary {
    color: var(--brand-blue);
    border-color: var(--brand-blue);
    font-weight: 700;

    &:hover {
      background-color: var(--brand-blue);
      color: white;
    }
  }

  .btn-primary {
    background-color: var(--brand-blue);
    border-color: var(--brand-blue);
    font-weight: 700;
  }
}

.concerning-level-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
}

.concern-level-label {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 8px;
}

.concern-level-tag {
  font-size: 12px;
  color: #fff;

  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 700;
}

.brief-note {
  display: flex;
  flex-direction: column;
  gap: 8px;

  textarea {
    height: 114px;
    padding: 16px;
    border-radius: 6px;
    border: 1px solid #dee2e6;
    width: 100%;
  }
}

.brief-note .note-options {
  display: flex !important;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;

  .btn-outline-primary {
    color: var(--brand-blue);
    border-color: var(--brand-blue);
    padding: 8px 12px;
    border-radius: 1000px;
    font-size: 14px;
    line-height: 1.2;

    &:hover {
      background-color: var(--brand-blue);
      color: white;
    }
  }
}

.analyze-button {
  background: var(--gradient-blue);
  background-blend-mode: soft-light, normal;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.analysis-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  overflow-y: auto;
}

.issue-wrapper {
  display: flex;
  gap: 24px;

  .issue-title {
    font-weight: 700;
    width: 100px;
  }

  .issue-description {
    flex: 1;
  }
}

@media (max-width: 1199px) {
  .try-it-out-container {
    flex-direction: column;

    .steps-section {
      flex: none;
      height: auto;
    }
  }

  .steps-section {
    margin-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .issue-wrapper {
    display: block !important;
  }
}

@media (max-width: 492px) {
  .try-it-out-container {
    padding-left: 0;
    padding-right: 0;
  }

  .concern-level-tag {
    display: block;
    width: fit-content;
  }

  .rc-steps-item {
    .rc-steps-item-container {
      display: flex;
      flex-direction: column;
    }
  }
}

/* Add this new rule for the blur effect above analysis-actions */
.analysis-step .preview-actions {
  position: relative;
}

.analysis-step .preview-actions::before {
  content: '';
  position: absolute;
  top: -40px; /* Height of the blur gradient, extending upward */
  left: 0;
  right: 0;
  height: 60px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  pointer-events: none; /* Allows clicking through */
  z-index: 1; /* Ensure it's above the content */
}
